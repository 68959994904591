import React, { useState } from "react";
import Main from "../components/main";

export default function AnionGapCalculator() {
  const [sodium, setSodium] = useState("");
  const [chloride, setChloride] = useState("");
  const [bicarbonate, setBicarbonate] = useState("");
  const [anionGap, setAnionGap] = useState("");

  const calculateAnionGap = () => {
    if (sodium && chloride && bicarbonate) {
      const result = (
        parseFloat(sodium) -
        (parseFloat(chloride) + parseFloat(bicarbonate))
      ).toFixed(1);
      setAnionGap(result);
    } else {
      alert("Please enter valid values for Sodium, Chloride, and Bicarbonate.");
    }
  };

  return (
    <Main>
      {/* SEO Metadata */}
      <head>
        <title>Anion Gap Calculator | Anion Gap Formula</title>
        <meta
          name="description"
          content="Calculate your anion gap using our accurate Anion Gap Calculator. Essential for assessing metabolic acidosis."
        />
        <meta
          name="keywords"
          content="anion gap calculation, anion gap formula, metabolic acidosis calculator"
        />
      </head>

      <div className="container mt-5">
        <div className="card border-0 shadow">
          <div className="card-body">
            {/* Title */}
            className="card-title text-center mb-4"
            {/* Description */}
            <p className="card-text text-muted">
            Anion Gap Calculator
              <br />
              <strong>Formula:</strong> Anion Gap = Sodium (Na⁺) - [Chloride (Cl⁻) + Bicarbonate (HCO₃⁻)]
              <br />
              <strong>Units:</strong> All inputs should be in <strong>mEq/L</strong>.
            </p>

            {/* Input Fields */}
            <div className="mb-3">
              <input
                type="number"
                className="form-control"
                placeholder="Enter Sodium (Na⁺) in mEq/L"
                value={sodium}
                onChange={(e) => setSodium(e.target.value)}
              />
            </div>
            <div className="mb-3">
              <input
                type="number"
                className="form-control"
                placeholder="Enter Chloride (Cl⁻) in mEq/L"
                value={chloride}
                onChange={(e) => setChloride(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <input
                type="number"
                className="form-control"
                placeholder="Enter Bicarbonate (HCO₃⁻) in mEq/L"
                value={bicarbonate}
                onChange={(e) => setBicarbonate(e.target.value)}
              />
            </div>

            {/* Calculate Button */}
            <div className="text-center">
              <button className="btn btn-primary" onClick={calculateAnionGap}>
                Calculate Anion Gap
              </button>
            </div>

            {/* Result Display */}
            {anionGap && (
              <div className="mt-4 text-center">
                <h4>Calculated Anion Gap: {anionGap} mEq/L</h4>
              </div>
            )}

            {/* Notes Section */}
            <div className="mt-4">
              <h4>Important Notes:</h4>
              <ul>
                <li>
                  Ensure all input values (Sodium, Chloride, and Bicarbonate) are measured in{" "}
                  <strong>mEq/L</strong>.
                </li>
                <li>Consult a healthcare professional for interpretation and clinical decisions.</li>
              </ul>
            </div>

            {/* Disclaimer */}
            <div className="mt-4">
              <h4>Disclaimer:</h4>
              <p>
                This Anion Gap Calculator is intended for educational purposes only. Always consult
                a qualified healthcare provider for accurate diagnosis and treatment.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Fixed Top-Right App Download Link */}
      <div className="app-download-link">
        <h4>Diabetes Sugar Tracker Free App for iOS</h4>
        <p>
          Download the free <strong>Diabetes Sugar Tracker</strong> app now, specially designed for seniors with diabetes.
        </p>
        <a
          href="https://apps.apple.com/np/app/diabetes-sugar-tracker/id6474657996"
          target="_blank"
          rel="noopener noreferrer"
          className="btn btn-success"
        >
          Download Now on iOS
        </a>
      </div>
    </Main>
  );
}
