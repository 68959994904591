import React, { useState } from "react";
import Main from "../components/main";

export default function A1CCalculator() {
  const [averageBloodSugar, setAverageBloodSugar] = useState("");
  const [a1c, setA1C] = useState("");

  const calculateA1C = () => {
    if (averageBloodSugar) {
      const result = ((parseFloat(averageBloodSugar) + 46.7) / 28.7).toFixed(1);
      setA1C(result);
    } else {
      alert("Please enter a valid estimated Average Glucose (eAG) value.");
    }
  };

  return (
    <Main>
      {/* SEO Meta Information */}
      <head>
        <title>A1C Conversion Calculator | Average A1C</title>
        <meta
          name="description"
          content="Calculate your A1C from eAG values easily with our A1C Conversion Calculator."
        />
        <meta
          name="keywords"
          content="A1C conversion calculator, average A1C calculator, eag to a1c calculator"
        />
      </head>

      <div>
        {/* Fixed Top-Right App Download Link */}
        <div className="app-download-link">
          <h4>Diabetes Sugar Tracker Free App for iOS</h4>
          <p>
            Download free  <strong>Diabetes Sugar Tracker</strong> app now, specially designed for seniors with diabetes.
          </p>
          <a
            href="https://apps.apple.com/np/app/diabetes-sugar-tracker/id6474657996"
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-success"
          >
            Download Now on iOS
          </a>
        </div>

        {/* Main Content */}
        <div className="main-content">
          <div className="card border-0 hover-shadow">
            <div className="card-body">
              <h3 className="card-title">A1C Conversion Calculator</h3>
              <p className="card-text">
                {/* Convert your estimated Average Glucose (eAG) into an A1C percentage. */}
                {/* <br /> */}
                This tool supports values in <strong>mg/dL</strong> units.
              </p>
              <input
                type="number"
                placeholder="Enter eAG (mg/dL)"
                className="form-control mb-2"
                value={averageBloodSugar}
                onChange={(e) => setAverageBloodSugar(e.target.value)}
              />
              <button className="btn btn-primary" onClick={calculateA1C}>
                Calculate A1C
              </button>
              {a1c && (
                <div className="mt-3">
                  <h4>Estimated A1C: {a1c} %</h4>
                </div>
              )}
              <br></br>
              <br></br>
              <br></br>
              <p>
                The formula  is:
                <br />
                <strong>A1C = (eAG + 46.7) / 28.7</strong>
                <br />
                Where <strong>eAG</strong> is your estimated average glucose in mg/dL.
              </p>
              <h4>Disclaimer</h4>
              <p>
                This eAG to A1C Conversion Calculator is for educational purposes only. Always consult your healthcare provider for personalized advice or treatment.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Main>
  );
}
