import React, { useState } from "react";
import Blogs from "./blogs";
import suga from "../images/sugar.png";
import note from "../images/note.png";
import weight from "../images/weights.png";
import medi from "../images/medications.png";
import Main from "../components/main";
import Product_card from "../components/product_card";

export default function About({ children }) {
  let content =
    "Diabetes Sugar Tracker is an app designed specifically for diabetes patients to manage diabetes daily. You can track blood sugar levels, record diet and symptoms, track weight, and manage medications all in a single app.";
  const [activeContent, setActiveContent] = useState("default");

  function handleProductClick(content) {
    setActiveContent(content);
  }

  return (
    <Main>
      {/* SEO Meta Information */}
      <head>
        <title>Diabetes Sugar Tracker App - Best Diabetes Management App</title>
        <meta name="description" content="Manage your diabetes effectively with our Diabetes Sugar Tracker App. Track blood sugar levels, diet, symptoms, weight, and medications all in one place." />
        <meta name="keywords" content="Diabetes Sugar Tracker App, Diabetes Management App, Blood Sugar Monitor, Glucose Tracking App, Manage Diabetes, Insulin Management App, Diabetes Weight Tracker, Medication Management for Diabetes, Diabetes Fitness Tracker, Diabetes Care App, Blood Glucose Tracker, Diabetes Monitoring App, Track Blood Sugar, Blood Sugar Log App, Diabetes Health Tracker, Daily Diabetes Tracker ,Blood Sugar Tracking,Diabetes Tracker,Blood Sugar Tracker app ,diabetes app, diabetes sugar app, diabetes management app, diabetes sugar tracker app, track blood sugar, diabetes care app, diabetes health tracker, diabetes tracker, blood sugar tracker app, best diabetes app, diabetes management tools, track diabetes, health monitoring app, diabetes care" />
      </head>
      
     
      
      <Blogs onClick={handleProductClick} />

      <h1>The Best Diabetes Sugar Tracker App for  Diabetes Management</h1>
    

      <div>{children}</div>
      <div>
        <Product_card activeContent="" content={content} />
      </div>

      <h2 className="text-primary">Features of the Diabetes Sugar Tracker App</h2>

      <div className="card-group m-auto" style={{ width: "100%" }}>
        <div className="card border-0 hover-shadow">
          <div className="card-body">
            <img src={suga} className="img-fluid m-2" style={{ width: "60px" }} alt="Blood Sugar Tracking" />
            <h3 className="card-title">Blood Sugar Tracking</h3>
            <p className="card-text text-sm">
              The Diabetes Sugar Tracker app allows you to easily add, update, or delete blood sugar readings, record essential details such as date, time, and blood sugar level, specify the unit of measurement in mg/dL or mmol/L, and categorize measurements based on meal times, including After Lunch, Before Lunch, After Dinner, Before Dinner, Breakfast, After or Before Sleep, Fasting, and other custom options.
            </p>
          </div>
        </div>

        <div className="card border-0 hover-shadow">
          <div className="card-body">
            <img src={note} className="m-2" style={{ width: "60px" }} alt="Add Notes" />
            <h3 className="card-title">Add Notes</h3>
            <p className="card-text">
              The Diabetes Sugar Tracker app enables you to capture important information such as diet and symptoms by adding notes and includes the date with your notes for accurate record-keeping and tracking.
            </p>
          </div>
        </div>

        <div className="card border-0 hover-shadow">
          <div className="card-body">
            <img src={weight} className="m-2" style={{ width: "60px" }} alt="Track Weight" />
            <h3 className="card-title">Track Weight</h3>
            <p className="card-text">
              The Diabetes Sugar Tracker app allows you to record weight, input details such as date and weight, choose the unit of measurement (Kilogram or Pound), and add additional notes to provide relevant details.
            </p>
          </div>
        </div>

        <div className="card border-0 hover-shadow">
          <div className="card-body">
            <img src={medi} className="m-2" style={{ width: "60px" }} alt="Manage Medication" />
            <h3 className="card-title">Manage Medication</h3>
            <p className="card-text">
              The Diabetes Sugar Tracker app enables you to effectively manage your medication information by allowing you to add new medications, specifying the unit of measure—units, mg, mL, or tablets, record dosage details in alignment with the selected unit of measure, and add additional notes related to medication.
            </p>
          </div>
        </div>
      </div>
    </Main>
  );
}
