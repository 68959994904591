import React, { useState } from "react";
import Main from "../components/main";

export default function BloodSugarConversion() {
  const [bloodSugar, setBloodSugar] = useState("");
  const [convertedBloodSugar, setConvertedBloodSugar] = useState("");
  const [unit, setUnit] = useState("mg/dL");

  const convertBloodSugar = () => {
    let result;

    if (bloodSugar) {
      if (unit === "mg/dL") {
        result = (parseFloat(bloodSugar) / 18).toFixed(2); // Convert mg/dL to mmol/L
        setConvertedBloodSugar(result);
      } else {
        result = (parseFloat(bloodSugar) * 18).toFixed(2); // Convert mmol/L to mg/dL
        setConvertedBloodSugar(result);
      }
    } else {
      alert("Please enter a valid blood sugar value.");
    }
  };

  return (
    <Main>
      {/* SEO Meta Information */}
      <head>
        <title>Blood Sugar Conversion Tool | Convert mg/dL to mmol/L</title>
        <meta
          name="description"
          content="Convert your blood sugar readings between mg/dL and mmol/L with our easy-to-use Blood Sugar Conversion Tool."
        />
        <meta
          name="keywords"
          content="A1C calculator conversion, diabetes converter, A1C converter calculator, blood glucose unit converter, blood sugar converter calculator, blood sugar unit converter, diabetes measurement converter, diabetes scale converter, diabetes unit converter, diabetic measurements converter, glucose conversion calculator, glucose measurements conversion, glucose unit conversion calculator, glucose unit converter, HbA1c converter calculator, HbA1c unit converter, sugar unit converter, blood sugar conversion tool"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no" />
      </head>

      <div className="container mt-4">
        {/* Fixed Top-Right App Download Link */}
        <div className="app-download-link text-center">
          <h4>Diabetes Sugar Tracker Free App for iOS</h4>
          <p>
            Download free <strong>Diabetes Sugar Tracker</strong> app now, specially designed for seniors with diabetes.
          </p>
          <a
            href="https://apps.apple.com/np/app/diabetes-sugar-tracker/id6474657996"
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-success btn-sm"
          >
            Download Now on iOS
          </a>
        </div>

        {/* Main Content */}
        <div className="main-content mt-4">
          <div className="card border-0 hover-shadow">
            <div className="card-body">
              <h3 className="card-title text-center">Blood Sugar Conversion Tool</h3>
              <p className="card-text text-center">
                This tool allows you to convert your blood sugar readings between <strong>mg/dL</strong> and <strong>mmol/L</strong>.
              </p>
              
              {/* Blood Sugar Input Field */}
              <input
                type="number"
                placeholder="Enter blood sugar value"
                className="form-control mb-3"
                value={bloodSugar}
                onChange={(e) => setBloodSugar(e.target.value)}
              />
              
              {/* Unit Selection */}
              <select
                className="form-control mb-3"
                value={unit}
                onChange={(e) => setUnit(e.target.value)}
              >
                <option value="" disabled>Select unit (mg/dL or mmol/L)</option>
                <option value="mg/dL">mg/dL</option>
                <option value="mmol/L">mmol/L</option>
              </select>
              
              {/* Convert Button */}
              <button className="btn btn-primary w-100" onClick={convertBloodSugar}>
                Convert Blood Sugar
              </button>

              {/* Display the Converted Value */}
              {convertedBloodSugar && (
                <div className="mt-3 text-center">
                  <h4>
                    Converted Blood Sugar: {convertedBloodSugar} {unit === "mg/dL" ? "mmol/L" : "mg/dL"}
                  </h4>
                </div>
              )}

              <br />
              <h4>Disclaimer</h4>
              <p>
                This Blood Sugar Conversion Tool is for educational purposes only. Always consult your healthcare provider for personalized advice or treatment.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Main>
  );
}
