import React from "react";
import Card from "../components/card";
import Main from "../components/main";

export default function Startup() {
  const trainingContent = (
    <div style={{ textAlign: "left", padding: "20px", backgroundColor: "#ffffff", color: "#000000" }}>
      <h3>Java Training for Beginners</h3>
      <p><strong>Duration:</strong> 15 days</p>
      <p><strong>Price:</strong> NR 2500 only</p>
      <p><strong>Start Date:</strong> January 25</p>
      <p><strong>Trainer:</strong> 14 years of experience in Java Development</p>
      <p><strong>Mode:</strong> Online</p>
      <h4>Our History</h4>
      <p>We have a proven track record of training over 200 students</p>
      <h4>Available Time Slots:</h4>
      <ul>
        <li>✅ 6 PM - 7 PM</li>
      </ul>
      <h4>What You'll Get:</h4>
      <ul>
        <li>Internship</li>
        <li>Certificate</li>
      </ul>
      <h4>This Course is Ideal For:</h4>
      <ul>
        <li>Students</li>
        <li>Beginners with no coding experience</li>
        <li>Those seeking a career in the IT industry</li>
        <li>Seeking to switch a career as a Java developer</li>
      </ul>
      <h4>Trainer Bio:</h4>
      <p>
        14+ Years of Software Development
        <br />
        <a href="https://www.linkedin.com/in/abishkar/" target="_blank" rel="noopener noreferrer">
          Connect with me on LinkedIn
        </a>
      </p>
      <h4>100% Money-Back Guarantee:</h4>
      <p>
        We are confident that you will gain value from this course. If the course does not meet your expectations within the first week, we offer a 100% money-back guarantee.
      </p>
      <h4>Get Started Today!</h4>
      <p>
        For more information or to enroll, contact us at:
        <br />
        📧 Email: info@dakshyaai.com
        <br />
        📞 Phone: +977 9814103736
        <br />
        🏢 Head Office: Dakshya A.I Pvt. Ltd., Prithivi Chowk, Pokhara, Nepal
        <br />
        🌐 Website: <a href="https://dakshyaai.com" target="_blank" rel="noopener noreferrer">https://dakshyaai.com</a>
      </p>
      <p>
        <a href="https://docs.google.com/forms/d/e/1FAIpQLSftDhId-5KTCulYi71Y7Lwjie2y_M78oP_A6nLu9Ti0xlR1vA/viewform" target="_blank" rel="noopener noreferrer">
          <strong>Register Now</strong>
        </a>
      </p>
      <p>
        <a href="https://docs.google.com/document/d/1b9vlPdvzXaZAxJRiY3CPe347RImDYyYjd6aeKX3LR7o/edit?tab=t.0#heading=h.65cff55u0w5" target="_blank" rel="noopener noreferrer">
          <strong>View Full Syllabus</strong>
        </a>
      </p>
    </div>
  );

  return (
    <>
      <Main>
        <Card activeContent="Java Training In Nepal" content={trainingContent} />
      </Main>
    </>
  );
}
