import React, { useState } from "react";
import Blogs from "./blogs";
import Main from "../components/main";
import Benefit_card from "../components/benefit_card";
export default function Faq({ children }) {
  let content =
    "Whether you're a new customer looking to learn more about what we offer or a user seeking clarification on specific topics,this page has clear information about our product.";
  const [activeContent, setActiveContent] = useState("default");

  function handleProductClick(content) {
    setActiveContent(content);
  }
  return (
    <Main>
      <Blogs onClick={handleProductClick} />
      <div>{children}</div>
      {/* <Blogs /> */}

      <div class="">
        <Benefit_card
          activeContent="Frequently Asked Question"
          content={content}
        />
      </div>

      <section class="bsb-faq-3 py-3 py-md-5 py-xl-8">
        <div class="container">
          <div class="row justify-content-md-center">
            <div class="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6"></div>
          </div>
        </div>

        {/* <!-- FAQs: My Account --> */}

        <div class="mb-8">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-11 col-xl-10">
                <div class="d-flex align-items-end mb-5"></div>
              </div>

              <div class="col-11 col-xl-10">
                <div class="accordion accordion-flush" id="faqAccount">
                  <div class="accordion-item bg-transparent border-top border-bottom py-3">
                    <h2 class="accordion-header" id="faqAccountHeading1">
                      <button
                        class="accordion-button collapsed bg-transparent fw-bold shadow-none link-primary"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqAccountCollapse1"
                        aria-expanded="false"
                        aria-controls="faqAccountCollapse1"
                      >
                        What information does the Diabetes Sugar Tracker app
                        collect?
                      </button>
                    </h2>

                    <div
                      id="faqAccountCollapse1"
                      class="accordion-collapse collapse"
                      aria-labelledby="faqAccountHeading1"
                    >
                      <div class="accordion-body">
                        <p>
                          The app collects health-related data that you
                          voluntarily provide including:
                          <ul>
                            <li>Weight</li>
                            <li>Blood sugar readings</li>
                            <li>Medication details</li>
                            <li>Notes related to your health</li>
                          </ul>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item bg-transparent border-bottom py-3">
                    <h2 class="accordion-header" id="faqAccountHeading2">
                      <button
                        class="accordion-button collapsed bg-transparent fw-bold shadow-none link-primary"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqAccountCollapse2"
                        aria-expanded="false"
                        aria-controls="faqAccountCollapse2"
                      >
                        Does the app collect any personal information?
                      </button>
                    </h2>
                    <div
                      id="faqAccountCollapse2"
                      class="accordion-collapse collapse"
                      aria-labelledby="faqAccountHeading2"
                    >
                      <div class="accordion-body">
                        <p>
                          No, we do not collect any personal information such as
                          your name, age, date of birth, or sex.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item bg-transparent border-bottom py-3">
                    <h2 class="accordion-header" id="faqAccountHeading3">
                      <button
                        class="accordion-button collapsed bg-transparent fw-bold shadow-none link-primary"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqAccountCollapse3"
                        aria-expanded="false"
                        aria-controls="faqAccountCollapse3"
                      >
                        How is my data used?
                      </button>
                    </h2>
                    <div
                      id="faqAccountCollapse3"
                      class="accordion-collapse collapse"
                      aria-labelledby="faqAccountHeading3"
                    >
                      <div class="accordion-body">
                        <p>
                          Your data is used solely for the purpose of providing
                          you with the services of our app which includes:
                          <ul>
                            <li>
                              Tracking and managing your blood sugar levels.
                            </li>
                            <li>
                              Helping you monitor the effectiveness of your
                              medication.
                            </li>
                            <li>
                              Assisting you in understanding how various factors
                              affect your blood sugar.
                            </li>
                          </ul>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item bg-transparent border-bottom py-3">
                    <h2 class="accordion-header" id="faqAccountHeading4">
                      <button
                        class="accordion-button collapsed bg-transparent fw-bold shadow-none link-primary"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqAccountCollapse4"
                        aria-expanded="false"
                        aria-controls="faqAccountCollapse4"
                      >
                        How is my data stored and secured?
                      </button>
                    </h2>
                    <div
                      id="faqAccountCollapse4"
                      class="accordion-collapse collapse"
                      aria-labelledby="faqAccountHeading4"
                    >
                      <div class="accordion-body">
                        <p>
                          <ul>
                            <li>
                              Local Storage: All data you enter into the app is
                              stored locally on your iPhone. We do not transmit
                              or store your data on any servers.
                            </li>
                            <li>
                              Data Deletion: If you delete the app from your
                              iPhone, all data stored within the app will be
                              permanently deleted and cannot be recovered.
                            </li>
                          </ul>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item bg-transparent border-bottom py-3">
                    <h2 class="accordion-header" id="faqAccountHeading5">
                      <button
                        class="accordion-button collapsed bg-transparent fw-bold shadow-none link-primary"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqAccountCollapse5"
                        aria-expanded="false"
                        aria-controls="faqAccountCollapse5"
                      >
                        Do I need to consent to any data practices to use the
                        app?
                      </button>
                    </h2>
                    <div
                      id="faqAccountCollapse5"
                      class="accordion-collapse collapse"
                      aria-labelledby="faqAccountHeading5"
                    >
                      <div class="accordion-body">
                        <p>
                          Yes, by using Diabetes Sugar Tracker, you consent to
                          the data practices described in our privacy policy.
                        </p>
                      </div>
                    </div>
                  </div>

                  {/* <div class="accordion-item bg-transparent border-top border-bottom py-3">
                    <h2 class="accordion-header" id="faqAccountHeading6">
                      <button
                        class="accordion-button collapsed bg-transparent fw-bold shadow-none link-primary"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqAccountCollapse6"
                        aria-expanded="false"
                        aria-controls="faqAccountCollapse6"
                      >
                        Is the app’s purchase price a one-time payment for
                        lifetime use?
                      </button>
                    </h2>

                    <div
                      id="faqAccountCollapse6"
                      class="accordion-collapse collapse"
                      aria-labelledby="faqAccountHeading6"
                    >
                      <div class="accordion-body">
                        <p>
                          Yes, once you purchase the app, it is free for
                          lifetime use.
                        </p>
                      </div>
                    </div>
                  </div> */}

                  <div class="accordion-item bg-transparent border-top border-bottom py-3">
                    <h2 class="accordion-header" id="faqAccountHeading7">
                      <button
                        class="accordion-button collapsed bg-transparent fw-bold shadow-none link-primary"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqAccountCollapse7"
                        aria-expanded="false"
                        aria-controls="faqAccountCollapse7"
                      >
                        Does the app display any ads?
                      </button>
                    </h2>

                    <div
                      id="faqAccountCollapse7"
                      class="accordion-collapse collapse"
                      aria-labelledby="faqAccountHeading7"
                    >
                      <div class="accordion-body">
                        <p>No, the app is ad-free.</p>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item bg-transparent border-top border-bottom py-3">
                    <h2 class="accordion-header" id="faqAccountHeading8">
                      <button
                        class="accordion-button collapsed bg-transparent fw-bold shadow-none link-primary"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqAccountCollapse8"
                        aria-expanded="false"
                        aria-controls="faqAccountCollapse8"
                      >
                        How will I be informed about changes to the Privacy
                        Policy?
                      </button>
                    </h2>

                    <div
                      id="faqAccountCollapse8"
                      class="accordion-collapse collapse"
                      aria-labelledby="faqAccountHeading8"
                    >
                      <div class="accordion-body">
                        <p>
                          We may update our Privacy Policy from time to time. We
                          will notify you of any changes by posting the new
                          Privacy Policy on this page.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item bg-transparent border-top border-bottom py-3">
                    <h2 class="accordion-header" id="faqAccountHeading9">
                      <button
                        class="accordion-button collapsed bg-transparent fw-bold shadow-none link-primary"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqAccountCollapse9"
                        aria-expanded="false"
                        aria-controls="faqAccountCollapse9"
                      >
                        How can I contact you if I have any questions about the
                        Privacy Policy?
                      </button>
                    </h2>

                    <div
                      id="faqAccountCollapse9"
                      class="accordion-collapse collapse"
                      aria-labelledby="faqAccountHeading9"
                    >
                      <div class="accordion-body">
                        <p>
                          If you have any questions about this Privacy Policy,
                          please contact us at:
                          <li>Email: info@dakshyaai.com</li>
                          <li>Address: Pokhara, Nepal</li>
                          For more information, please visit our website.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Main>
  );
}
